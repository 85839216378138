module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../packages/gatsby-theme-sanity-pages/gatsby-browser.js'),
      options: {"plugins":[],"dataset":"sr-default-prod","excludedPaths":["/"],"localDev":false},
    },{
      plugin: require('../../../packages/gatsby-theme-news/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../packages/gatsby-theme-news/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"/news/","dataset":"sr-default-prod","localDev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P67446V","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"vUgq3zjcRdc5ttA7bI7jHw","gtmPreview":"env-2","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SmartRent","short_name":"SmartRent","start_url":"/","background_color":"#1a1f27","theme_color":"#1a1f27","display":"minimal-ui","icon":"src/images/favicon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb22f268ec78d7fa9c4da0aeed27e371"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
